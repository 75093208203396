'use strict';
import * as smoothScroll from "smooth-scroll";

declare const $: any;

const $window = $(window);

$(() => {
	const $body = $('body');

	const scroll = new smoothScroll('a[href*="#"]', {
		header   : '#header',
		speed    : 500,
		offset   : 50,
		updateURL: false,
	});

	const viewportSwitcher = new ViewportSwitcher();
	viewportSwitcher.init();

	const headerSmaller = new HeaderSmaller();
	headerSmaller.init();

	const collapse = new Collapse();
	collapse.init();

	$('.drawr-button').on('touchstart click', function(event) {
		event.preventDefault();
		$('html').addClass('off-canvas-open');
	});

	$('.close-button').on('touchstart click', function(event) {
		event.preventDefault();
		$('html').removeClass('off-canvas-open');
	});

	// (function loop() {
	// 	window.requestAnimationFrame(loop);
	// })();
});

const viewportSwitcherState = {
	$viewport : document.querySelector('#viewport'),
	breakpoint: {
		1: 768,
		2: 1024,
	}
};

export class ViewportSwitcher {
	private $viewport;
	private breakpoint;

	constructor(config: any = viewportSwitcherState) {
		this.$viewport  = config.$viewport;
		this.breakpoint = config.breakpoint;
	}

	init() {
		$window.on('resize.ViewportSwitcher orientationchange.ViewportSwitcher', () => {
			if ( screen.width < this.breakpoint[1] ) {
				this.$viewport.setAttribute('content', `width=414`);
			} else if ( screen.width <= this.breakpoint[2] ) {
				this.$viewport.setAttribute('content', `width=1280`);
			} else {
				this.$viewport.setAttribute('content', 'width=device-width');
			}

			// console.log(`viewport ${$window.width()}, ${$window.height()}`);
		}).trigger('resize.ViewportSwitcher');
	}
}

const headerSmallerState = {
	$header         : $('.global-header'),
	headerSmallPoint: 150,
	headerSmallClass: 'is-small',
};

export class HeaderSmaller {
	$header;
	headerSmallPoint;
	headerSmallClass;

	constructor(config: any = headerSmallerState) {
		this.$header          = config.$header;
		this.headerSmallPoint = config.headerSmallPoint;
		this.headerSmallClass = config.headerSmallClass;
	}

	init() {
		let scrollTop = $window.scrollTop();
		$window.on('scroll.HeaderSmaller', () => {
			scrollTop = $window.scrollTop();
			if ( scrollTop > this.headerSmallPoint ) {
				this.$header.addClass(this.headerSmallClass);
			} else {
				this.$header.removeClass(this.headerSmallClass);
			}
		}).trigger('scroll.HeaderSmaller');
	}
}

const collapseState = {
	$target : $('[data-collapse]'),
	duration: 'normal',
};

export class Collapse {
	$target;
	duration;

	constructor(config: any = collapseState) {
		this.$target  = config.$target;
		this.duration = config.duration;
	}

	init() {
		$.each(this.$target, (key, target) => {
			const $target = $(target);
			const trigger = $target.attr('data-collapse');

			$target.find(trigger).on('click', (event) => {
				const $this = $(event.currentTarget);

				if ( $target.hasClass('is-open') ) {
					$target.removeClass('is-open');
					$this.next().slideUp(this.duration);
				} else {
					$target.addClass('is-open');
					$this.next().slideDown(this.duration);
				}
			});
		});
	}
}


const NewsTickerState = {
	$target : null,
	duration: 1000,
	interval: 5000,
};

export class NewsTicker {
	$target;
	duration;
	interval;

	constructor(config: { $target: any, duration?: number, interval?: number } = NewsTickerState) {
		this.$target  = config.$target;
		this.duration = config.duration || NewsTickerState.duration;
		this.interval = config.interval || NewsTickerState.interval;
	}

	init() {
		var $setElm     = this.$target;
		var effectSpeed = this.duration;
		var switchDelay = this.interval;
		var easing      = 'swing';

		$setElm.each(function() {
			var effectFilter = $(this).attr('rel'); // 'fade' or 'roll' or 'slide'

			var $targetObj = $(this);
			var $targetUl  = $targetObj.children('ul');
			var $targetLi  = $targetObj.find('li');
			var $setList   = $targetObj.find('li:first');

			var ulWidth    = $targetUl.width();
			var listHeight = $targetLi.height();
			$targetObj.css({height: (listHeight)});
			$targetLi.css({top: '0', left: '0', position: 'absolute'});

			if ( effectFilter == 'fade' ) {
				$setList.css({
					display: 'block',
					opacity: '0',
					zIndex : '98'
				}).stop().animate({opacity: '1'}, effectSpeed, easing).addClass('showlist');
				setInterval(function() {
					var $activeShow = $targetObj.find('.showlist');
					$activeShow.animate({opacity: '0'}, effectSpeed, easing, function() {
						$(this).next().css({
							display: 'block',
							opacity: '0',
							zIndex : '99'
						}).animate({opacity: '1'}, effectSpeed, easing).addClass('showlist').end().appendTo($targetUl).css({
							display: 'none',
							zIndex : '98'
						}).removeClass('showlist');
					});
				}, switchDelay);
			} else if ( effectFilter == 'roll' ) {
				$setList.css({top: '3em', display: 'block', opacity: '0', zIndex: '98'}).stop().animate({
					top    : '0',
					opacity: '1'
				}, effectSpeed, easing).addClass('showlist');
				setInterval(function() {
					var $activeShow = $targetObj.find('.showlist');
					$activeShow.animate({top: '-3em', opacity: '0'}, effectSpeed, easing).next().css({
						top    : '3em',
						display: 'block',
						opacity: '0',
						zIndex : '99'
					}).animate({
						top    : '0',
						opacity: '1'
					}, effectSpeed, easing).addClass('showlist').end().appendTo($targetUl).css({zIndex: '98'}).removeClass('showlist');
				}, switchDelay);
			} else if ( effectFilter == 'slide' ) {
				$setList.css({left: (ulWidth), display: 'block', opacity: '0', zIndex: '98'}).stop().animate({
					left   : '0',
					opacity: '1'
				}, effectSpeed, easing).addClass('showlist');
				setInterval(function() {
					var $activeShow = $targetObj.find('.showlist');
					$activeShow.animate({left: (-(ulWidth)), opacity: '0'}, effectSpeed, easing).next().css({
						left   : (ulWidth),
						display: 'block',
						opacity: '0',
						zIndex : '99'
					}).animate({
						left   : '0',
						opacity: '1'
					}, effectSpeed, easing).addClass('showlist').end().appendTo($targetUl).css({zIndex: '98'}).removeClass('showlist');
				}, switchDelay);
			}
		});
	}
}